@import '../../index.scss';

.Modal {
    width: 800px !important;
}

.Modal img {
    width: 200px;
}

.ModalLinks {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.ModalLinks h3, .ModalLinks p {
    margin: 0;
}

.ModalImages {
    display: grid;
    justify-content: center;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, 200px);
}

.ButtonModal {
    width: 150px;
    height: 50px;
    background-color: $primaryColor;
    font-size: 20px
}

.ant-modal-title {
    font-size: 32px;
}