@import '../../index.scss';

.Contact {
    min-height: 450px;
    min-height: 100vh;
    background-color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;

    position: relative;
}

.Contact h1 {
    margin: 0 0 10px 0;
}

.ContactProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    z-index: 2;
}

.ContactInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
    z-index: 2;
}

.ContactSeparator {
    border: 1px solid $primaryColor;
}


.image {
    background-color: black;
    width: 290px;
    border-radius: 50%;
}

.iconsContainer, .iconsContainer a {
    font-size: 40px;
    color: $primaryColor;
}

.iconsContainer a {
    cursor: pointer;
    padding: 0 5px;
}

@media screen and (max-width: 810px){
    .Contact {flex-direction: column; gap: 0; text-align: center;}
    .aboutText {align-items: center;}
    .ContactInfo {align-items: center;}
    .ContactSeparator {width: 80%; }
}

@media screen and (max-height: 750px) {
    .ContactProfile {padding-top: 80px;}
  }