@import '../../index.scss';

.About {
    height: 100vh;
    background-color: $secondaryColor;

    display: flex;

    justify-content: space-evenly;
    align-items: center;

    padding: 0 1rem;

    position: relative;
}

.aboutText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;

    z-index: 2;
}

.aboutText h1 {
    color: black;
}

.aboutText a {
    width: 250px;
    height: 65px;
    background-color: $primaryColor;
    color: $secondaryColor;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}

.aboutText a:hover {
    background-color: $thirdColor;
}

.About p {
    color: $thirdColor;
    max-width: 600px;
    font-size: 18px;
}

.aboutImg {
    min-width: 324px;
    min-height: 432px;
    border: 3px solid $thirdColor;
    background: linear-gradient(0deg, rgba(31, 29, 29, 0.3), rgba(63, 54, 138, 0.3)), url("../../assets/images/about.jpeg");
    background-size: cover;
    z-index: 2;
}

@media screen and (max-width: 600px){
    .aboutImg {display: none;}
    .aboutText {align-items: flex-start; text-align: center;}
    .About p {margin: 0}
    .aboutText a {width: 150px; height: 50px;}
}

@media screen and (max-width: 1000px) {
    .aboutImg {display: none;}
    .About {align-items: flex-start; padding: 200px 0;}
}

@media screen and (max-height: 800px){
    .About {padding: 60px 0 0 0; min-height: min-content;}
    .aboutText {gap: 20px;}
    .aboutText h1 {margin: 0;}
    .aboutText a {width: 150px; height: 50px;}
    .About p {font-size: 20px;}
}

@media screen and (max-height: 550px){
    .About {padding: 60px 0 0 0; min-height: min-content;}
    .aboutText {gap: 0px;}
    .aboutText h1 {margin: 0;}
    .aboutText a {width: 100px; height: 40px;}
    .About p {font-size: 15px;}
}
