@import '../../index.scss';

.Portfolio {
    background-color: $primaryColor;
    min-height: 100vh;
    padding: 50px 25px;
}

.PortfolioHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 100px 80px 0;
    color: white;
    font-size: 28px;
}

.PortfolioHeader h1 {
    color: white;
}

.projectCards {
    display: grid;
    padding: 30px;
    
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: 250px;
    gap: 50px;
}


@media screen and (max-width: 600px){
    .PortfolioHeader {padding: 70px 0;}
    .projectCards {
        padding: 0; 
        gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-template-rows: 250px;
        text-align: center;
    }
}

@media screen and (max-height: 800px){
    .PortfolioHeader {padding-top: 70px; margin: 0;}
    .projectCards {padding: 0;}
}