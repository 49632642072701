@import '../../index.scss';

.homeContainer {
    background: linear-gradient(180deg, rgba(20, 44, 80, 0) 54.69%, rgba(20, 44, 80, 0.71) 78.65%), url("../../assets/images/hero_background.png") no-repeat center;
    background-size: cover;
    position: relative;
    height: 100vh;
    background-color: $primaryColor;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;
}

.homeContent {
    display: flex;
    justify-content: space-around;
    margin-bottom: 6vh;
}

.homeContainer p {
    font-size: 58px;
    color: #FFFFFF;
    line-height: 72px;

    width: 70vw;
    font-weight: 500;
    margin: 0 0 5vh 5vw;
}

.HomeIcons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 40px;

    position: absolute;
    right: 3vw;
    bottom: 10vh;
}

.HomeIcons a {
    color: white;
}


@media screen and (max-width: 600px){
    .homeContainer p {font-size: 30px; line-height: 48px; padding-bottom: 0; width: 90%; text-shadow: 0 0 3px black}
    .HomeIcons {
        bottom: 10vh;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        font-size: 40px;
        gap: 20px;
        position: initial;
        right: 3vw;
    }
}

@media screen and (max-height: 750px){
    .homeContainer p {font-size: 32px; line-height: 48px; padding-bottom: 0px;}
    .homeContainer {min-height: min-content;}
}