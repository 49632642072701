@import '../../index.scss';

.Footer {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444444;
    color: $secondaryColor;
    gap: 5px;

    position: relative;
}

// .Footer:last-child {
//     justify-content: flex-end;
// }

.Footer p {
    margin: 0;
}

.Footer img {
    position: absolute;
    right: 0;
    width: 60px;
}