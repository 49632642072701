@import '~antd/dist/antd.css';

body {
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$primaryColor: #142c50;
$secondaryColor: #FFFFFF;
$thirdColor: #5A1807;

$primaryFont: 'Unna', cursive;
$secondaryFont: 'Confortaa', cursive;
$thirdFont: 'Forum', monospace;

body {
  // width: 100vw;
  font-family: $thirdFont;
}

h1 {
  font-size: 48px;
  color: $primaryColor;
}

p {
  line-height: 1.5em;
}

.triangle {
  min-width: 1400px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

@media screen and (max-height: 750px) {
  .triangle {height: 100vh; }
}