.CV {
    width: 100%;
    position: relative;
}

.react-pdf__Page {
    position: relative;
    width: 70vw !important;
}

.react-pdf__Page__canvas {
    width: inherit !important;
    height: inherit !important;
}

.CV__Download {
    position: absolute;
    margin: 1em;
    top: 0;
    right: 0;

    width: 150px;
    height: 40px;
    border-radius: 10px;
    color: grey;
}

.CV__Container .react-pdf__Document {
    min-width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CV__Container .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    margin: 1em;
}

.CV__Container p {
    display: none;
}

@media screen and (max-width: 600px){
    .react-pdf__Page {
        width: 110vw !important;
        // height: 100vh;
    }
}