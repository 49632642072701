@import '../../index.scss';

.ProjectCard {
    // width: 400px;
    height: 250px;

    background-color: rgb(179, 177, 177);
    border-radius: 10px;

    // cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ProjectCard:hover {
    background-color: #FAFAFA;
}

.ProjectCard h1 {
    text-align: center;
}