@import '../../index.scss';
@import '~antd/dist/antd.css';

.header {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    font-family: $primaryFont;
    background-color: $primaryColor;

    position: fixed;
    top: 0;
    z-index: 1000;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header a {
    height: 100%;
    color: $secondaryColor;
    text-decoration: none;
    cursor: pointer;
    font-size: 21px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 4px solid transparent;

    padding: 0 15px;
}

.header a:hover {
    border-bottom: 4px solid #FAFAFA;
}

.logo {
    align-self: center;
    width: 100px;
    height: 50px;
    cursor: pointer;
    margin-left: 20px;
}

.dropdown {
    display: none
}

.menu {
    background-color: #FAFAFA;
    width: 150px;
    text-align: center;
}

.menu a {
    font-size: 20px;
}

.menuItem:hover {
    background-color: #cecece;
}

.buttonMenu {
    height: 100%;
    background-color: transparent !important;
    border: 0;
    color: #FAFAFA;
    font-size: 36px;
    padding: 5px;
    cursor: pointer;
}

.buttonMenu:hover {
    box-shadow: 0;
    outline: none;
    background-color: transparent;
}

.active {
    border-bottom: 4px solid #FAFAFA !important;
}

@media screen and (max-width: 600px) {
    .navbar {display: none}
    .dropdown {display: block}
    .header {padding: 10px 20px;}
}